import { Link } from 'gatsby'
import React from 'react'
import cn from 'classnames'

import * as st from '~/assets/styl/BottomBar.module.styl'
import { useMediaQuery } from 'react-responsive'

import { bottomBarItems } from '~/data/'

export default function BottomBar() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1081px)' })

  return (
    <>
      {(!isDesktop && (
        <nav className={cn(st.bottomBarContainer)}>
          <ul>
            {bottomBarItems.map(({ icon, slug }, index) => (
              <li key={index}>
                <Link to={slug}>
                  <span className={icon}></span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )) || (
        <div className={st.desktopFooter}>
          <span>Pointer ® - Todos os direitos reservados</span>
        </div>
      )}
    </>
  )
}
