export default [
  {
    icon: 'icon-rate-15',
    value: 1,
  },
  {
    icon: 'icon-rate-25',
    value: 2,
  },
  {
    icon: 'icon-rate-35',
    value: 3,
  },
  {
    icon: 'icon-rate-45',
    value: 4,
  },
  {
    icon: 'icon-rate-55',
    value: 5,
  },
]
