export default [
  {
    icon: 'icon-instagram',
    slug: 'https://www.instagram.com/pointer.revestimentos/',
  },
  {
    icon: 'icon-youtube',
    slug: 'https://www.youtube.com/channel/UCOoaJuBymaH4RKaOKgSVmcw',
  },
  {
    icon: 'icon-facebook',
    slug: 'https://www.facebook.com/pointer.revestimentos/',
  },
  {
    icon: 'icon-pinterest-p',
    slug: 'https://br.pinterest.com/pointerrevestimentos/',
  },
]
