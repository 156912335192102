import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoBackButton from './GoBackButton'
import cn from 'classnames'

import * as st from '~/assets/styl/EditForm.module.styl'
import { useContext } from '~/hooks'
import useApi from '~/siteApi'
import { navigate } from 'gatsby'

export default function EditForm(props) {
  const { profile, token, setProfile } = useContext()
  const { tipo } = props
  const [name, setName] = useState(profile?.name || '')
  const [ok, setOk] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmError, setConfirmError] = useState('')
  const [oldError, setOldError] = useState('')

  function submitNameEdit(e) {
    e.preventDefault()
    useApi(
      'patch',
      'accounts/profile',
      { name },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        setProfile({ ...profile, name })
        setOk(true)
        setTimeout(() => {
          navigate('#')
        }, 1500)
      })
      .catch(({ errors }) => {
        setErrorMessage(errors.name)
        setError(true)
      })
  }

  function submitPasswordEdit(e) {
    e.preventDefault()
    useApi(
      'patch',
      'accounts/profile/password',
      {
        oldpassword: oldPassword,
        password: newPassword,
        confirm: confirmPassword,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        setOk(true)
        setTimeout(() => {
          navigate('#')
        }, 1500)
      })
      .catch(({ errors }) => {
        setPasswordError(errors.password)
        setConfirmError(errors.confirm)
        setOldError(errors.oldpassword)
        setError(true)
      })
  }

  return (
    <div className={st.editContainer}>
      <div className={st.editWrapper}>
        {(tipo === 'nome' && (
          <>
            <h1>Alterar nome</h1>
            <form onSubmit={submitNameEdit}>
              <label>
                <span className="icon-user"></span>
                <input
                  type="text"
                  placeholder="Digite seu nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
              <p className={cn({ [st.ok]: ok })}>Nome alterado com sucesso!</p>
              <span className={cn(st.errorMessage, { [st.visible]: error })}>
                {errorMessage}
              </span>
              <button type="submit">Salvar</button>
            </form>
          </>
        )) ||
          (tipo === 'senha' && (
            <>
              <h1>Alterar senha</h1>
              <form onSubmit={submitPasswordEdit}>
                <label>
                  <span className="icon-password"></span>
                  <input
                    type="password"
                    placeholder="Insira a senha atual"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </label>
                <span className={cn(st.errorMessage, { [st.visible]: error })}>
                  {oldError}
                </span>
                <label>
                  <span className="icon-password"></span>
                  <input
                    type="password"
                    placeholder="Insira a nova senha"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </label>
                <span className={cn(st.errorMessage, { [st.visible]: error })}>
                  {passwordError}
                </span>
                <label>
                  <span className="icon-password"></span>
                  <input
                    type="password"
                    placeholder="Repita a senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </label>
                <p className={cn({ [st.ok]: ok })}>
                  Senha alterada com sucesso!
                </p>
                <span className={cn(st.errorMessage, { [st.visible]: error })}>
                  {confirmError}
                </span>
                <button type="submit">Salvar</button>
              </form>
            </>
          ))}
      </div>
    </div>
  )
}

EditForm.propTypes = {
  tipo: PropTypes.string.isRequired,
}
