export default [
  {
    icon: 'icon-camera',
    slug: '/meu-ambiente/',
  },
  {
    icon: 'icon-stores',
    slug: '/onde-comprar/',
  },
  {
    icon: 'icon-flag',
    slug: '/',
  },
  {
    icon: 'icon-calculator',
    slug: '/calculadora/',
  },
  {
    icon: 'icon-faq',
    slug: '/faq/',
  },
]
