// extracted by mini-css-extract-plugin
export var bottomBarContainer = "BottomBar-module--bottom-bar-container--82009";
export var desktopFooter = "BottomBar-module--desktopFooter--fe8fa";
export var grecaptchaBadge = "BottomBar-module--grecaptcha-badge--fde06";
export var hideOnDesktop = "BottomBar-module--hide-on-desktop--5af83";
export var hideOnMobile = "BottomBar-module--hide-on-mobile--9f4d0";
export var home = "BottomBar-module--home--2c573";
export var iconAccountDefault = "BottomBar-module--icon-account-default--e707f";
export var iconArrow = "BottomBar-module--icon-arrow--fbbac";
export var iconBrand = "BottomBar-module--icon-brand--f8f57";
export var iconBrand1 = "BottomBar-module--icon-brand1--2b8a6";
export var iconBrand2 = "BottomBar-module--icon-brand2--e5e93";
export var iconCalculator = "BottomBar-module--icon-calculator--b2457";
export var iconCamera = "BottomBar-module--icon-camera--10674";
export var iconCheckbox = "BottomBar-module--icon-checkbox--57169";
export var iconChevron = "BottomBar-module--icon-chevron--789d5";
export var iconDelete = "BottomBar-module--icon-delete--e1aca";
export var iconDisclaimer = "BottomBar-module--icon-disclaimer--6847d";
export var iconDone = "BottomBar-module--icon-done--b3b0b";
export var iconDownload = "BottomBar-module--icon-download--9ac1d";
export var iconEdit = "BottomBar-module--icon-edit--3b943";
export var iconFacebook = "BottomBar-module--icon-facebook--ae15f";
export var iconFaq = "BottomBar-module--icon-faq--d12a5";
export var iconFaqUp = "BottomBar-module--icon-faq-up--84ead";
export var iconFilledHeart = "BottomBar-module--icon-filled-heart--e9b51";
export var iconFlag = "BottomBar-module--icon-flag--36555";
export var iconHeart = "BottomBar-module--icon-heart--e9161";
export var iconInstagram = "BottomBar-module--icon-instagram--d95b8";
export var iconLog = "BottomBar-module--icon-log--f9578";
export var iconLoginArrow = "BottomBar-module--icon-login-arrow--a57ac";
export var iconLogout = "BottomBar-module--icon-logout--1b707";
export var iconPassword = "BottomBar-module--icon-password--481c1";
export var iconPinterestP = "BottomBar-module--icon-pinterest-p--23192";
export var iconRate15 = "BottomBar-module--icon-rate-15--e180c";
export var iconRate25 = "BottomBar-module--icon-rate-25--f4869";
export var iconRate35 = "BottomBar-module--icon-rate-35--9d0c6";
export var iconRate45 = "BottomBar-module--icon-rate-45--0d490";
export var iconRate55 = "BottomBar-module--icon-rate-55--54d33";
export var iconRating = "BottomBar-module--icon-rating--ef412";
export var iconSocial = "BottomBar-module--icon-social--3225d";
export var iconSocial1 = "BottomBar-module--icon-social1--4ae7c";
export var iconSocial2 = "BottomBar-module--icon-social2--ceb0f";
export var iconStores = "BottomBar-module--icon-stores--aca21";
export var iconTrophy = "BottomBar-module--icon-trophy--a2f41";
export var iconUser = "BottomBar-module--icon-user--d20a5";
export var iconUserCircle = "BottomBar-module--icon-user-circle--54821";
export var iconYoutube = "BottomBar-module--icon-youtube--6c264";
export var pageWidth = "BottomBar-module--page-width--05f9c";
export var view = "BottomBar-module--view--5a19f";
export var views = "BottomBar-module--views--c32db";