import React, { useEffect, useState } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { serialize } from 'object-to-formdata'

import cn from 'classnames'

import * as st from '~/assets/styl/Menu.module.styl'

import guiaLogo from '~/assets/svg/logo.svg'

import defaultImg from '~/assets/img/default.jpg'
import Link from './Link'
import Modal from './Modal'
import { useContext } from '~/hooks'
import useApi from '~/siteApi'
import { navigate } from 'gatsby'

import { desktopMenu } from '~/data'
import { useMediaQuery } from 'react-responsive'
import { Oval } from 'react-loader-spinner'
import EditForm from './EditForm'

export default function Menu() {
  const { profile, token, setToken, setProfile } = useContext()
  const isLogged = Boolean(profile)
  const [menuOpen, setMenuOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalImage, setModalImage] = useState<File>()
  const [imagePreview, setImagePreview] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(1)

  useEffect(() => {
    if (modalImage) {
      const preview = URL.createObjectURL(modalImage)
      setImagePreview(preview)
      return () => URL.revokeObjectURL(preview)
    } else {
      setImagePreview('')
    }
  }, [modalImage])

  const isDesktop = useMediaQuery({ query: '(min-width: 1081px)' })

  function handleLogout() {
    // setIsLogged(false)
    useApi('get', 'auth/logout', {
      headers: { Authorization: `Bearer ${token}` },
    }).then(() => {
      setToken(null)
      setProfile(null)
      navigate('/')
      setMenuOpen(false)
    })
  }

  function submitImage(e) {
    e.preventDefault()
    setIsLoading(true)
    useApi(
      'post',
      'accounts/profile/avatar',
      serialize({
        fileUpload: modalImage || '',
      }),
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then(() => {
      setProfile({ ...profile, avatar: URL.createObjectURL(modalImage) })
      setIsLoading(false)
      setModalOpen(false)
    })
  }

  function deleteImage() {
    useApi('delete', 'accounts/profile/avatar', {
      headers: { Authorization: `Bearer ${token}` },
    }).then(() => {
      setProfile({ ...profile, avatar: null })
      setImagePreview(defaultImg)
    })
  }

  return (
    <>
      <div
        className={cn(st.menuContainer, {
          [st.active]: menuOpen,
        })}
      >
        <div className={st.menuBar}>
          <AnchorLink to="/" onAnchorLinkClick={() => setMenuOpen(false)}>
            <img
              src={guiaLogo}
              alt="Logo da Guia (em cinza) Pointer (em laranja)"
            />
          </AnchorLink>
          <div className={st.menuAndButtons}>
            <div className={st.menuItemWrapper}>
              {isDesktop && (
                <nav>
                  <ul>
                    {desktopMenu.map((item, index) => (
                      <li key={index}>
                        <Link href={item.slug}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>
            <div className={st.buttonWrapper}>
              {isLogged ? (
                <>
                  {/* <AnchorLink to="/">
                    <span className="icon-trophy"></span>
                  </AnchorLink> */}
                  <img
                    src={profile.avatar || defaultImg}
                    alt="Imagem do perfil"
                    onClick={() => {
                      setMenuOpen(!menuOpen), setActiveTab(1)
                    }}
                  />
                </>
              ) : (
                <>
                  <Link className={st.login} href="/login/">
                    <span className="icon-log"></span>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={cn(st.menuWrapper, 'page-width', {
            [st.active]: menuOpen,
          })}
        >
          {(activeTab === 1 && (
            <>
              <div className={st.upperWrapper}>
                <button
                  onClick={() => setMenuOpen(false)}
                  className={st.backButton}
                  type="button"
                >
                  <span className="icon-chevron"></span>
                  Voltar
                </button>
                <button
                  className={st.upperLogoff}
                  type="button"
                  onClick={handleLogout}
                >
                  <span className="icon-logout"></span>
                </button>
              </div>
              <div className={st.menuItems}>
                <div className={st.imageWrapper}>
                  <div>
                    <img
                      src={(profile && profile.avatar) || defaultImg}
                      alt="Imagem do perfil"
                    />
                    <span
                      onClick={() => setModalOpen(!modalOpen)}
                      className="icon-edit"
                    ></span>
                  </div>
                  {profile && profile.name}
                </div>
                <div className={st.itemsWrapper}>
                  <div className={st.menuItem}>
                    <span>Configurações de conta</span>
                    <button onClick={() => setActiveTab(2)}>
                      {isDesktop && <span className="icon-chevron"></span>}
                      Alterar nome
                      {!isDesktop && <span className="icon-chevron"></span>}
                    </button>
                    <button onClick={() => setActiveTab(3)}>
                      {isDesktop && <span className="icon-chevron"></span>}
                      Segurança da conta
                      {!isDesktop && <span className="icon-chevron"></span>}
                    </button>
                  </div>
                  {/* <div className={st.menuItem}>
                <span>Certificados e Classificação</span>
                <AnchorLink to="/">
                  {isDesktop && <span className="icon-chevron"></span>}
                  Ver minha Classificação geral
                  {!isDesktop && <span className="icon-chevron"></span>}
                </AnchorLink>
                <AnchorLink to="/">
                  {isDesktop && <span className="icon-chevron"></span>}
                  Ver meus certificados
                  {!isDesktop && <span className="icon-chevron"></span>}
                </AnchorLink>
              </div> */}
                  <div className={st.menuItem}>
                    <span>Ajuda e suporte</span>
                    <AnchorLink
                      onAnchorLinkClick={() =>
                        setTimeout(() => setMenuOpen(false), 500)
                      }
                      to="/faq"
                    >
                      {isDesktop && <span className="icon-chevron"></span>}
                      Dúvidas Frequentes
                      {!isDesktop && <span className="icon-chevron"></span>}
                    </AnchorLink>
                  </div>
                </div>
                <button
                  className={st.logoff}
                  type="button"
                  onClick={handleLogout}
                >
                  <span className="icon-logout"></span>
                  Deslogar
                </button>
              </div>
            </>
          )) ||
            (activeTab === 2 && (
              <div className={st.edits}>
                <button
                  onClick={() => setActiveTab(1)}
                  className={st.backButton}
                  type="button"
                >
                  <span className="icon-chevron"></span>
                  Voltar
                </button>
                <EditForm tipo="nome" />
              </div>
            )) ||
            (activeTab === 3 && (
              <div className={st.edits}>
                <button
                  onClick={() => setActiveTab(1)}
                  className={st.backButton}
                  type="button"
                >
                  <span className="icon-chevron"></span>
                  Voltar
                </button>
                <EditForm tipo="senha" />
              </div>
            ))}
        </div>
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        {() => (
          <div className={st.modal}>
            <section>
              <h2>Editar foto</h2>
              <div className={st.imageModalWrapper}>
                <img
                  src={imagePreview || profile.avatar || defaultImg}
                  alt="Imagem do perfil"
                />
                {profile.avatar && (
                  <span
                    onClick={() => deleteImage()}
                    className="icon-delete"
                  ></span>
                )}
              </div>
              <form onSubmit={submitImage}>
                <label>
                  <button
                    className={st.upload}
                    onClick={(e) => {
                      e.preventDefault()
                      document.getElementById('upload').click()
                    }}
                  >
                    Upload
                  </button>
                  <input
                    id="upload"
                    type="file"
                    accept="image/"
                    onChange={(e) => setModalImage(e.target.files[0])}
                    style={{ display: 'none' }}
                  />
                </label>
                <button type="submit">
                  {isLoading ? (
                    <Oval
                      height="25"
                      width="25"
                      color="#282b3e"
                      secondaryColor="#CB0103"
                      ariaLabel="loading"
                      wrapperStyle={{ justifyContent: 'center' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </button>
              </form>
            </section>
          </div>
        )}
      </Modal>
    </>
  )
}
