import React, { createContext, useEffect, useState } from 'react'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import Menu from '~/components/Menu'
import BottomBar from '~/components/BottomBar'
import { useCookies } from 'react-cookie'
import useApi from '~/siteApi'
import SnackbarProvider from 'react-simple-snackbar'

const pages = ['/login/', '/registro/', '/esqueci-minha-senha/', '/404']

interface Context {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  refreshToken: { token: string; expiration: number }
  setRefreshToken: React.Dispatch<
    React.SetStateAction<{ token: string; expiration: number }>
  >
  profile: Profile
  setProfile: React.Dispatch<React.SetStateAction<Profile>>
}

interface Profile {
  role: {
    name: string
    slug: string
  }
  name: string
  email: string
  partner: boolean
  store: {
    slug: string
  }
  avatar: string
  account_steps: [
    {
      step: number
      rating: number
      finished: boolean
    }
  ]
  studies_favorites: [
    {
      study: number
    }
  ]
  progress: [
    {
      study: number
      progress: number
      total: number
      steps: number
    }
  ]
}

export const context = createContext<Context>(null)

const Layout = ({ children, location }: PageProps) => {
  const [cookies, setCookie, removeCookie] = useCookies()
  const [token, setToken] = useState<string>(cookies.token)
  const [refreshToken, setRefreshToken] = useState<{
    token: string
    expiration: number
  }>(cookies.refresh_token)
  const [profile, setProfile] = useState<Profile>(cookies.profile)

  useEffect(() => {
    if (token && refreshToken) {
      const login = () =>
        useApi(
          'post',
          'token/refresh',
          { refresh_token: refreshToken.token },
          { headers: { Authorization: `Bearer ${token}` } }
        ).then(({ token, refresh_token, refresh_token_expiration }) => {
          useApi('get', 'accounts/profile', {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then(({ result }) => {
              setToken(token)
              setProfile(result)
              setRefreshToken({
                token: refresh_token,
                expiration: refresh_token_expiration,
              })
              setCookie('token', token, {
                path: '/',
                maxAge: Math.floor(
                  refresh_token_expiration - Date.now() / 1000
                ),
              })
              setCookie(
                'refresh_token',
                {
                  token: refresh_token,
                  expiration: refresh_token_expiration,
                },
                { path: '/' }
              )
            })
            .catch(() => {
              setToken(null)
              setProfile(null)
              setRefreshToken(null)
              removeCookie('profile', { path: '/' })
              removeCookie('token', { path: '/' })
              removeCookie('refresh_token', { path: '/' })
            })
        })
      const f = setInterval(
        login,
        Math.min(refreshToken.expiration * 1000 - Date.now() - 120000, 7200000)
      )
      return () => clearInterval(f)
    } else if (!token) {
      setToken(null)
      setProfile(null)
      setRefreshToken(null)
      removeCookie('profile', { path: '/' })
      removeCookie('token', { path: '/' })
      removeCookie('refresh_token', { path: '/' })
    }
  }, [token, refreshToken])

  return (
    <>
      <context.Provider
        value={{
          token,
          setToken: (token) => {
            setToken(token)
            if (!token) removeCookie('token', { path: '/' })
          },
          profile,
          setProfile: (profile) => {
            setProfile(profile)
            if (profile) setCookie('profile', profile, { path: '/' })
            else removeCookie('profile', { path: '/' })
          },
          refreshToken,
          setRefreshToken: (token) => {
            setRefreshToken(token)
            if (token) setCookie('refresh_token', token, { path: '/' })
            else removeCookie('refresh_token', { path: '/' })
          },
        }}
      >
        {!pages.includes(location.pathname) && <Menu />}
        <TransitionProvider
          location={location}
          mode="immediate"
          enter={{
            opacity: 0,
          }}
          usual={{
            opacity: 1,
          }}
          leave={{
            opacity: 0,
            config: {
              duration: 200,
            },
          }}
        >
          <SnackbarProvider>
            <TransitionViews>{children}</TransitionViews>
          </SnackbarProvider>
        </TransitionProvider>
        {!pages.includes(location.pathname) && <BottomBar />}
      </context.Provider>
    </>
  )
}

export default Layout
