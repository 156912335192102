exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calculadora-tsx": () => import("./../../../src/pages/calculadora.tsx" /* webpackChunkName: "component---src-pages-calculadora-tsx" */),
  "component---src-pages-curso-tsx": () => import("./../../../src/pages/curso.tsx" /* webpackChunkName: "component---src-pages-curso-tsx" */),
  "component---src-pages-esqueci-minha-senha-tsx": () => import("./../../../src/pages/esqueci-minha-senha.tsx" /* webpackChunkName: "component---src-pages-esqueci-minha-senha-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-meu-ambiente-tsx": () => import("./../../../src/pages/meu-ambiente.tsx" /* webpackChunkName: "component---src-pages-meu-ambiente-tsx" */),
  "component---src-pages-modulo-tsx": () => import("./../../../src/pages/modulo.tsx" /* webpackChunkName: "component---src-pages-modulo-tsx" */),
  "component---src-pages-onde-comprar-tsx": () => import("./../../../src/pages/onde-comprar.tsx" /* webpackChunkName: "component---src-pages-onde-comprar-tsx" */),
  "component---src-pages-passo-tsx": () => import("./../../../src/pages/passo.tsx" /* webpackChunkName: "component---src-pages-passo-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */)
}

