export default [
  {
    title: 'Home',
    slug: '/',
  },
  {
    title: 'Simulador',
    slug: '/meu-ambiente/',
  },
  {
    title: 'Calculadora',
    slug: '/calculadora/',
  },
  {
    title: 'Onde Comprar',
    slug: '/onde-comprar/',
  },
  // {
  //   title: 'Classificações',
  //   slug: '#',
  // },
  // {
  //   title: 'Configurações',
  //   slug: '#',
  // },
  // {
  //   title: 'Suporte',
  //   slug: '#',
  // },
]
